import React, { useEffect } from 'react';
import { RouterProps } from '../../Router';
import { Prompt, match, withRouter } from 'react-router-dom';
import NoMobile from '../../shared/NoMobile/NoMobile';
import { useDispatch, useSelector } from 'react-redux';
import { reset } from '../../controllers/GenericEditorController/GenericEditorActions';
import { loadFormData } from '../../controllers/FormsController/FormsService';
import { Entry, Form } from '../../@Types/@Types';
import GenericFormEditor from '../../shared/GenericFormEditor/GenericFormEditor';
import FormConfig from './FormConfig/FormConfig';
import * as FormService from '../../controllers/FormsController/FormsService';
import { FormStep } from '../../@Types/FormTypes/FormStep';
import { FormStepLocation, UniqueSteps } from '../../@Types/FormTypes/Form';
import { RootState } from '../../utils/_store';
import { EditorConfig } from '../../controllers/GenericEditorController/GenericEditorReducer';
import ReducerFunctions from './FormReducerFunctions';
import { FormStepMapper } from './FormStepMappers';
import StepMenu from './StepMenu/StepMenu';
import StepConfigBtn from './StepConfigBtn/StepConfigBtn';
import StepConditionsBtn from './FormCondition/FormCondition';
import { checkAdmin } from '../../utils/PermissionsFunctions';
interface apiKeyRouteParam {
    /** Current entry */
    apiKey: string;
    /** Current Project */
    idProject: string;
}

interface DataType {
    form: Form;
    entry: Entry;
}
const idEditor = 'FORM';

function FormComponent({
    match,
    mobile,
}: {
    match: match<apiKeyRouteParam>;
} & RouterProps): JSX.Element {
    const dispatch = useDispatch();
    const siteInfo = useSelector((state: RootState) => state.site);
    useEffect(() => {
        if (match.params.apiKey) {
            dispatch(
                reset(idEditor, {
                    loadParams: [match.params.idProject, match.params.apiKey],
                    loadData: loadFormData,
                    getInfoOnSuccess: (data: DataType) => data.form,
                    calcExtraSteps: (data: DataType) => data.form.hiddenSteps,
                    ...ReducerFunctions,
                } as EditorConfig<Form, any, any, any, any>)
            );
        }
    }, [match.params.idProject, match.params.apiKey]);

    useEffect(() => {
        const onBeforeUnload = (e: any): void => {
            if (
                siteInfo.user?.email === 'andres@arquimedes.co' ||
                siteInfo.user?.email === 'cristina.acuna@capta.co'
            ) {
                e.preventDefault();
                e.returnValue = '';
            }
        };
        window.addEventListener('beforeunload', onBeforeUnload);
        return (): void => {
            window.removeEventListener('beforeunload', onBeforeUnload);
        };
    }, []);

    if (mobile) {
        return <NoMobile />;
    }
    return (
        <React.Fragment>
            {(siteInfo.user?.email === 'andres@arquimedes.co' ||
                siteInfo.user?.email === 'cristina.acuna@capta.co') && (
                <Prompt message="Salir?" />
            )}
            <GenericFormEditor<
                Form,
                FormStep,
                UniqueSteps,
                FormStepLocation,
                DataType
            >
                mobile={mobile}
                idEditor={idEditor}
                stepMenuComponent={<StepMenu />}
                multiSection
                configComponent={<FormConfig />}
                customStepBtns={(props): JSX.Element => (
                    <>
                        <StepConfigBtn {...props} />
                        <StepConditionsBtn
                            idStep={props.step.id}
                            condition={props.step.condition}
                            handleUpdate={(condition): void =>
                                props.updateStep({ ...props.step, condition })
                            }
                        />
                    </>
                )}
                editSize={checkAdmin(siteInfo.user)}
                editId={checkAdmin(siteInfo.user)}
                editStyle
                calcCurrentRoute={(project, data, sectionName): string => {
                    let route =
                        project?.name +
                        ' / Entradas / ' +
                        data?.entry?.entryName;
                    if (sectionName) {
                        route += ' / ' + sectionName;
                    }
                    return route;
                }}
                getFormFromData={(data): any => {
                    return data.form;
                }}
                handleSave={async (form, data): Promise<void> => {
                    await FormService.saveForm(
                        match.params.idProject,
                        data.entry.apiKey,
                        {
                            ...data.form,
                            ...form,
                        },
                        siteInfo.entities
                    );
                }}
                stepMapper={FormStepMapper}
                stepMapperProps={{
                    integrations: siteInfo.organization!.integrations,
                }}
            />
        </React.Fragment>
    );
}
export default withRouter((props: any) => <FormComponent {...props} />);
